import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import { logoutSuccess } from 'ducks/auth/actions';
import _ from 'lodash';
import {
  addField,
  updateFormFields,
  updateFormInfo,
  formsSuccess,
  formInfoSuccess,
  setForm,
  updateConfig,
  resetForm,
  formFieldsSuccess,
  formEmailSettingsSuccess,
  updateFormStatusSuccess,
  deleteFormFieldSuccess,
} from './actions';
import initialState from './states';
import { FORM_TABS } from './types';
import { INPUTS_DESCRIPTION } from 'app/types/form-builder';
import { ENTITY_STATUS } from 'app/types/constants';

export default handleActions(
  {
    [formsSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [formInfoSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.formID]: payload,
        },
      };
    },
    [addField](state, { payload: { type, label, index } }) {
      const { name, defaultLabel } = INPUTS_DESCRIPTION[type];
      const newState = _.cloneDeep(state);
      const newField = {
        formFieldID: uuidv4(),
        type,
        config: {
          name,
          required: false,
          label: label || defaultLabel,
        },
      };

      if (state.config.tab === FORM_TABS.CREATOR) {
        const newFields = newState.form.creatorFields.active;
        newFields.splice(_.isNumber(index) ? index : newFields.length, 0, newField);
        newState.form.creatorFields.active = [...newFields];
      } else if (state.config.tab === FORM_TABS.REVIEWER) {
        const newFields = newState.form.reviewerFields.active;
        newFields.splice(_.isNumber(index) ? index : newFields.length, 0, newField);
        newState.form.reviewerFields.active = [...newFields];
      }

      return newState;
    },
    [updateFormFields](state, { payload }) {
      const newState = _.cloneDeep(state);

      if (state.config.tab === FORM_TABS.CREATOR) {
        newState.form.creatorFields = payload(newState.form.creatorFields);
      } else if (state.config.tab === FORM_TABS.REVIEWER) {
        newState.form.reviewerFields = payload(newState.form.reviewerFields);
      }

      return newState;
    },
    [updateFormInfo](state, { payload }) {
      return {
        ...state,
        form: {
          ...state.form,
          ...payload,
        },
      };
    },
    [setForm](state, { payload: { formID } }) {
      const formInfo = state.info[formID];

      if (!formInfo) {
        return {
          ...state,
          form: initialState.form,
        };
      }

      return {
        ...state,
        form: {
          ...formInfo,
          creatorFields: {
            active: formInfo.fields.filter(({ status }) => status === ENTITY_STATUS.ACTIVE),
            deleted: formInfo.fields.filter(({ status }) => status === ENTITY_STATUS.DELETED),
          },
          reviewerFields: {
            active: formInfo.reviewerFields.filter(({ status }) => status === ENTITY_STATUS.ACTIVE),
            deleted: formInfo.reviewerFields.filter(({ status }) => status === ENTITY_STATUS.DELETED),
          },
        },
      };
    },
    [updateConfig](state, { payload }) {
      return {
        ...state,
        config: {
          ...state.config,
          ...payload,
        },
      };
    },
    [resetForm](state) {
      return {
        ...state,
        form: initialState.form,
      };
    },
    [formFieldsSuccess](state, { payload: { formID, fields } }) {
      return {
        ...state,
        fields: {
          ...state.fields,
          [formID]: fields,
        },
      };
    },
    [formEmailSettingsSuccess](state, { payload: { formID, payload } }) {
      return {
        ...state,
        emailSettings: {
          ...state.emailSettings,
          [formID]: payload,
        },
      };
    },
    [updateFormStatusSuccess](state, { payload: { formID, status } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [formID]: {
            ...state.info[formID],
            status,
          },
        },
      };
    },
    [deleteFormFieldSuccess](state, { payload: { formFieldID } }) {
      return {
        ...state,
        form: {
          ...state.form,
          creatorFields: {
            ...state.form.creatorFields,
            deleted: state.form.creatorFields.deleted.filter((field) => field.formFieldID !== formFieldID),
          },
          reviewerFields: {
            ...state.form.reviewerFields,
            deleted: state.form.reviewerFields.deleted.filter((field) => field.formFieldID !== formFieldID),
          },
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
