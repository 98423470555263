// Constants for different requests for current reducer
export default {
  forms: 'FORMS',
  formInfo: 'FORM_INFO',
  createForm: 'CREATE_FORM',
  updateForm: 'UPDATE_FORM',
  updateFormSettings: 'UPDATE_FORM_SETTINGS',
  deleteForm: 'DELETE_FORM',
  deleteFormField: 'DELETE_FORM_FIELD',
  fillForm: 'FILL_FORM',
  formFields: 'FORM_FIELDS',
  formEmailSettings: 'FORM_EMAIL_SETTIGNS',
  updateFormEmailSettings: 'UPDATE_FORM_EMAIL_SETTINGS',
  updateFormStatus: 'UPDATE_FORM_STATUS',
};
