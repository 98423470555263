import { handleActions } from 'redux-actions';
import initialState from './states';

import { biToursOverviewSuccess, biToursSiteActivitySuccess, biToursSummarySuccess, setLoadingState } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [biToursSummarySuccess](state, { payload }) {
      return {
        ...state,
        summary: payload,
      };
    },
    [setLoadingState](state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },
    [biToursOverviewSuccess](state, { payload }) {
      return {
        ...state,
        overview: payload,
      };
    },
    [biToursSiteActivitySuccess](state, { payload }) {
      return {
        ...state,
        activity: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
