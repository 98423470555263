import preparedReducer from './reducer';
import {
  addField,
  updateFormFields,
  updateFormInfo,
  createForm,
  updateForm,
  deleteForm,
  deleteFormField,
  requestForms,
  requestFormInfo,
  updateConfig,
  fillForm,
  updateFormSettings,
  resetForm,
  setForm,
  requestFormFields,
  requestFormEmailSettings,
  updateFormEmailSettings,
  updateFormStatus,
} from './actions';
import { ENTITY_STATUS } from 'app/types/constants';

export const reducer = preparedReducer;

export const SLICE_NAME = 'forms';

export const selectors = (state) => state[SLICE_NAME];

export const selectFormInfo = (state, formID) => state[SLICE_NAME].info[formID];

export const selectActiveFormFields = (state, formID) =>
  state[SLICE_NAME].info[formID]?.fields?.filter(({ status }) => status === ENTITY_STATUS.ACTIVE) || [];

export const actions = {
  addField,
  updateFormFields,
  updateFormInfo,
  createForm,
  updateForm,
  deleteForm,
  deleteFormField,
  requestForms,
  requestFormInfo,
  updateConfig,
  fillForm,
  updateFormSettings,
  resetForm,
  setForm,
  requestFormFields,
  requestFormEmailSettings,
  updateFormEmailSettings,
  updateFormStatus,
};
