import preparedReducer from './reducer';
import { requestBIToursSummary, requestBIToursOverview, requestBIToursSiteActivity } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'biTours';

export const selectors = (state) => state[SLICE_NAME];
export const selectToursSummary = (state) => state[SLICE_NAME].summary;
export const selectToursOverview = (state) => state[SLICE_NAME].overview;
export const selectToursSiteActivity = (state) => state[SLICE_NAME].activity;

export const actions = {
  requestBIToursSummary,
  requestBIToursOverview,
  requestBIToursSiteActivity,
};
