import preparedReducer from './reducer';
import {
  requestSites,
  requestAddSite,
  updateSite,
  updateSiteForms,
  requestSiteForms,
  requestVisitorLogSetting,
  updateVisitorLogSetting,
  requestVisitorLogSettings,
  requestSiteMobileSettings,
  updateSiteMobileSettings,
} from './actions';
import { createSelector } from 'reselect';

export const reducer = preparedReducer;

export const SLICE_NAME = 'sites';

export const selectors = (state) => state[SLICE_NAME];

export const selectVisitorLogSetting = (state, siteID) => state[SLICE_NAME].visitorLogSettings[siteID];

export const getActiveSitesSelect = createSelector(selectors, ({ list }) =>
  list
    .filter(({ status }) => status === 'A')
    .map(({ siteID, siteName }) => ({
      key: siteID,
      label: siteName,
    }))
);

export const actions = {
  requestSites,
  requestAddSite,
  updateSite,
  updateSiteForms,
  requestSiteForms,
  requestVisitorLogSetting,
  updateVisitorLogSetting,
  requestVisitorLogSettings,
  requestSiteMobileSettings,
  updateSiteMobileSettings,
};
