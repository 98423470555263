import constants from './constants';
import { createFailure, createRequest, createSuccess } from 'ducks/helpers';

export const sitesRequest = createRequest(constants.sites);
export const sitesSuccess = createSuccess(constants.sites);
export const sitesFailure = createFailure(constants.sites);

export const addSiteRequest = createRequest(constants.addSite);
export const addSiteSuccess = createSuccess(constants.addSite);
export const addSiteFailure = createFailure(constants.addSite);

export const updateSiteRequest = createRequest(`${constants.sites}_UPDATE`);
export const updateSiteSuccess = createSuccess(`${constants.sites}_UPDATE`);
export const updateSiteFailure = createFailure(`${constants.sites}_UPDATE`);

export const updateSiteFormsRequest = createRequest(`${constants.sites}_UPDATE_FORMS`);
export const updateSiteFormsSuccess = createSuccess(`${constants.sites}_UPDATE_FORMS`);
export const updateSiteFormsFailure = createFailure(`${constants.sites}_UPDATE_FORMS`);

export const siteFormsRequest = createRequest(`${constants.sites}_REQUEST_FORMS`);
export const siteFormsSuccess = createSuccess(`${constants.sites}_REQUEST_FORMS`);
export const siteFormsFailure = createFailure(`${constants.sites}_REQUEST_FORMS`);

export const visitorLogSettingRequest = createRequest(`${constants.sites}_REQUEST_VISITOR_LOG_SETTING`);
export const visitorLogSettingSuccess = createSuccess(`${constants.sites}_REQUEST_VISITOR_LOG_SETTING`);
export const visitorLogSettingFailure = createFailure(`${constants.sites}_REQUEST_VISITOR_LOG_SETTING`);

export const visitorLogSettingsRequest = createRequest(`${constants.sites}_REQUEST_VISITOR_LOG_SETTINGS`);
export const visitorLogSettingsSuccess = createSuccess(`${constants.sites}_REQUEST_VISITOR_LOG_SETTINGS`);
export const visitorLogSettingsFailure = createFailure(`${constants.sites}_REQUEST_VISITOR_LOG_SETTINGS`);

export const updateVisitorLogSettingRequest = createRequest(`${constants.sites}_UPDATE_VISITOR_LOG_SETTING`);
export const updateVisitorLogSettingSuccess = createSuccess(`${constants.sites}_UPDATE_VISITOR_LOG_SETTING`);
export const updateVisitorLogSettingFailure = createFailure(`${constants.sites}_UPDATE_VISITOR_LOG_SETTING`);

export const siteMobileSettingsRequest = createRequest(`${constants.sites}_REQUEST_SITE_MOBILE_SETTINGS`);
export const siteMobileSettingsSuccess = createSuccess(`${constants.sites}_REQUEST_SITE_MOBILE_SETTINGS`);
export const siteMobileSettingsFailure = createFailure(`${constants.sites}_REQUEST_SITE_MOBILE_SETTINGS`);

export const updateSiteMobileSettingsRequest = createRequest(`${constants.sites}_UPDATE_SITE_MOBILE_SETTING`);
export const updateSiteMobileSettingsSuccess = createSuccess(`${constants.sites}_UPDATE_SITE_MOBILE_SETTING`);
export const updateSiteMobileSettingsFailure = createFailure(`${constants.sites}_UPDATE_SITE_MOBILE_SETTING`);

export const requestSites = sitesRequest;
export const requestAddSite = addSiteRequest;
export const updateSite = updateSiteRequest;
export const updateSiteForms = updateSiteFormsRequest;
export const requestSiteForms = siteFormsRequest;
export const requestVisitorLogSetting = visitorLogSettingRequest;
export const requestVisitorLogSettings = visitorLogSettingsRequest;
export const updateVisitorLogSetting = updateVisitorLogSettingRequest;
export const requestSiteMobileSettings = siteMobileSettingsRequest;
export const updateSiteMobileSettings = updateSiteMobileSettingsRequest;
