import { ANALYTICS_TOURS_SUMMARY, ANALYTICS_TOURS_OVERVIEW, ANALYTICS_TOURS_SITE_ACTIVITY } from 'app/api-routes';
import axios from 'axios';
import { put, all, takeLatest } from 'redux-saga/effects';
import {
  biToursSummaryFailure,
  biToursSummarySuccess,
  biToursSummaryRequest,
  setLoadingState,
  biToursOverviewFailure,
  biToursOverviewSuccess,
  biToursOverviewRequest,
  biToursSiteActivitySuccess,
  biToursSiteActivityFailure,
  biToursSiteActivityRequest,
} from './actions';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'app/types/constants';

function* biToursSummarySaga({ payload: { month, year } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  try {
    yield put(biToursSummarySuccess([]));
    yield put(setLoadingState(true));

    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_SUMMARY, { params });
    yield put(biToursSummarySuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursSummaryFailure(err));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* biToursOverviewSaga({ payload: { month, year, siteID } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_OVERVIEW, { params });
    yield put(biToursOverviewSuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursOverviewFailure(err));
  }
}

function* biToursSiteActivitySaga({ payload: { month, year, siteID } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_SITE_ACTIVITY, { params });
    yield put(biToursSiteActivitySuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursSiteActivityFailure(err));
  }
}

export default function* () {
  yield all([
    takeLatest(biToursOverviewRequest, biToursOverviewSaga),
    takeLatest(biToursSummaryRequest, biToursSummarySaga),
    takeLatest(biToursSiteActivityRequest, biToursSiteActivitySaga),
  ]);
}
