import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FORMS } from 'app/api-routes';
import axios from 'axios';
import _ from 'lodash';
import {
  createFormFailure,
  createFormRequest,
  createFormSuccess,
  deleteFormFailure,
  deleteFormFieldFailure,
  deleteFormFieldRequest,
  deleteFormFieldSuccess,
  deleteFormRequest,
  deleteFormSuccess,
  fillFormFailure,
  fillFormRequest,
  fillFormSuccess,
  formEmailSettingsFailure,
  formEmailSettingsRequest,
  formEmailSettingsSuccess,
  formFieldsFailure,
  formFieldsRequest,
  formFieldsSuccess,
  formInfoFailure,
  formInfoRequest,
  formInfoSuccess,
  formsFailure,
  formsRequest,
  formsSuccess,
  setForm,
  updateFormEmailSettingsFailure,
  updateFormEmailSettingsRequest,
  updateFormEmailSettingsSuccess,
  updateFormFailure,
  updateFormRequest,
  updateFormSettingsFailure,
  updateFormSettingsRequest,
  updateFormSettingsSuccess,
  updateFormStatusFailure,
  updateFormStatusRequest,
  updateFormStatusSuccess,
  updateFormSuccess,
} from './actions';
import {
  CHECKBOXES_INPUT,
  DATE_INPUT,
  DATE_TIME_INPUT,
  LABEL_INPUT,
  PHOTOS_INPUT,
  SIGNATURE_INPUT,
} from 'app/types/form-builder';
import { MediaAssetTables, MediaAssetTypes } from 'ducks/mediaAssets/types';
import { bulkUploadMediaAssetsSaga, uploadMediaAssetSaga } from 'ducks/mediaAssets/sagas';
import { formatDateTimeUTC, formatLocalDateTime } from 'util/formatting';
import { SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

const serializeFields = (fields) =>
  fields.map((field, index) => ({
    ...field,
    formFieldID: _.isNumber(field.formFieldID) ? field.formFieldID : null,
    sequence: index + 1,
    config: JSON.stringify(field.config),
  }));

export const deserializeFields = (fields) =>
  fields.map((field) => ({
    ...field,
    config: JSON.parse(field.config),
  }));

const serializeValueByType = (type, value) => {
  switch (type) {
    case LABEL_INPUT:
    case CHECKBOXES_INPUT:
      return (value || []).join(';');

    case DATE_TIME_INPUT:
      return value ? formatDateTimeUTC(value, SERVER_DATE_TIME_FORMAT) : undefined;

    case DATE_INPUT:
      return value ? formatLocalDateTime(value, SERVER_DATE_FORMAT) : undefined;

    default:
      return value;
  }
};

function* formsSaga() {
  try {
    const { payload: forms } = yield axios.get(FORMS);

    yield put(formsSuccess(forms));
  } catch (err) {
    yield put(formsFailure(err));
  }
}

function* formInfoSaga({ payload: { formID, setupForm = false } }) {
  try {
    const { payload } = yield axios.get(`${FORMS}/${formID}`);

    const deserializedFields = deserializeFields(payload.fields);
    const deserializedReviewerFields = deserializeFields(payload.reviewerFields);

    const form = {
      ...payload,
      fields: deserializedFields,
      reviewerFields: deserializedReviewerFields,
    };

    yield put(formInfoSuccess(form));
    if (setupForm) {
      yield put(
        setForm({
          formID,
        })
      );
    }
  } catch (err) {
    yield put(formInfoFailure(err));
  }
}

function* createFormSaga({ payload }) {
  try {
    const fields = serializeFields(payload.creatorFields.active);
    const reviewerFields = serializeFields(payload.reviewerFields.active);

    const serializedForm = {
      ..._.omit(payload, ['creatorFields', 'reviewerFields']),
      fields,
      reviewerFields,
    };

    const { payload: id } = yield axios.post(FORMS, { payload: serializedForm });
    yield put(createFormSuccess(id));
  } catch (err) {
    yield put(createFormFailure(err));
  }
}

function* updateFormSaga({ payload: { form, onSuccess, onFailure } }) {
  try {
    const fields = serializeFields([
      ...(form.creatorFields.active || []),
      ...(form.creatorFields.deleted || []),
    ]);
    const reviewerFields = serializeFields([
      ...(form.reviewerFields.active || []),
      ...(form.reviewerFields.deleted || []),
    ]);

    const serializedForm = {
      ..._.omit(form, ['creatorFields', 'reviewerFields']),
      fields,
      reviewerFields,
    };

    yield axios.put(`${FORMS}/${form.formID}`, { payload: serializedForm });
    yield call(formInfoSaga, {
      payload: {
        formID: form.formID,
        setupForm: true,
      },
    });
    yield put(updateFormSuccess({ formID: form.formID }));
    onSuccess?.(form.formID);
  } catch (err) {
    yield put(updateFormFailure(err));
    onFailure?.(form.formID);
  }
}

function* updateFormSettingsSaga({ payload: { formID, values } }) {
  try {
    const {
      name,
      visibleOnClientPortal,
      creatorRoles,
      creatorAccess,
      reviewerRoles,
      reviewerAccess,
      siteIDs,
    } = values;

    const payload = {
      formID,
      name,
      visibleOnClientPortal,
      creatorRoles,
      creatorAccess,
      reviewerRoles,
      reviewerAccess,
      siteIDs,
    };

    yield axios.put(`${FORMS}/${formID}/settings`, { payload });
    yield call(formInfoSaga, { payload: { formID, setupForm: true } });
    yield put(updateFormSettingsSuccess({ formID, ...values }));
  } catch (err) {
    yield put(updateFormSettingsFailure(err));
  }
}

function* updateFormStatusSaga({ payload: { formID, status } }) {
  try {
    yield axios.put(`${FORMS}/${formID}/status/${status}`);
    yield put(updateFormStatusSuccess({ formID, status }));
  } catch (err) {
    yield put(updateFormStatusFailure(err));
  }
}

function* deleteFormSaga({ payload: formID }) {
  try {
    yield axios.delete(`${FORMS}/${formID}`);
    yield put(deleteFormSuccess(formID));
  } catch (err) {
    yield put(deleteFormFailure(err));
  }
}

function* deleteFormFieldSaga({ payload: { formID, formFieldID, force } }) {
  try {
    yield axios.delete(`${FORMS}/${formID}/fields/${formFieldID}`, { params: { force } });
    yield put(deleteFormFieldSuccess({ formID, formFieldID }));
  } catch (err) {
    yield put(deleteFormFieldFailure(err));
  }
}

function* fillFormSaga({
  payload: {
    formID,
    payload: { clientID, siteID, fieldValues },
  },
}) {
  try {
    const fieldResults = [];

    const valuesEntries = Object.entries(fieldValues);
    for (let index = 0; index < valuesEntries.length; index++) {
      const [formFieldID, payload] = valuesEntries[index];
      const fieldResult = yield call(prepareFieldResult, { formID, formFieldID, payload });

      fieldResults.push(fieldResult);
    }

    const payload = {
      formID,
      clientID,
      siteID,
      shiftID: undefined,
      fieldResults,
    };

    const { payload: id } = yield axios.post(`${FORMS}/${formID}/fill`, { payload });
    yield put(fillFormSuccess(id));
  } catch (err) {
    yield put(fillFormFailure(err));
  }
}

function* formFieldsSaga({ payload: formID }) {
  try {
    const { payload } = yield axios.get(`${FORMS}/${formID}/fields`);
    const fields = deserializeFields(payload);

    yield put(formFieldsSuccess({ formID, fields }));
  } catch (err) {
    yield put(formFieldsFailure(err));
  }
}

function* updateFormEmailSettingsSaga({ payload: { formID, siteID, values } }) {
  try {
    yield axios.post(
      `${FORMS}/${formID}/email-settings`,
      { payload: values },
      {
        params: { siteID },
      }
    );

    yield put(updateFormEmailSettingsSuccess({ formID, siteID, payload: values }));
  } catch (err) {
    yield put(updateFormEmailSettingsFailure(err));
  }
}

function* formEmailSettingsSaga({ payload: { formID, siteID } }) {
  try {
    const { payload } = yield axios.get(`${FORMS}/${formID}/email-settings`, {
      params: { siteID },
    });

    yield put(formEmailSettingsSuccess({ formID, payload }));
  } catch (err) {
    yield put(formEmailSettingsFailure(err));
  }
}

export function* prepareFieldResult({ formID, formFieldID, payload }) {
  const { type, value, tableName, entityID, formFieldResultID } = payload;

  switch (type) {
    case PHOTOS_INPUT: {
      const uploadedPhotos = [];
      const newPhotos = [];
      (value || []).forEach((item) => {
        if (item.mediaAssetID) {
          uploadedPhotos.push(item);
        } else {
          newPhotos.push(item);
        }
      });

      const files = newPhotos.map((item) => ({
        fileUploaded: item.photo,
        mediaAssetInfo: {
          entityID: formID,
          description: item.description,
          tableName: MediaAssetTables.FORMS,
          type: MediaAssetTypes.PHOTO,
        },
      }));

      const newMediaAssetIDs = yield call(bulkUploadMediaAssetsSaga, { payload: { files } });
      const uploadedMediaAssetIDs = uploadedPhotos.map((item) => item.mediaAssetID);

      return {
        formFieldResultID,
        formFieldID,
        value: [...uploadedMediaAssetIDs, ...newMediaAssetIDs].join(';'),
        tableName,
        entityID,
      };
    }

    case SIGNATURE_INPUT: {
      const mediaAssetID = yield call(uploadMediaAssetSaga, {
        payload: {
          fileUploaded: value,
          mediaAssetInfo: {
            entityID: formID,
            description: 'signature',
            tableName: MediaAssetTables.FORMS,
            type: MediaAssetTypes.PHOTO,
          },
        },
      });
      return {
        formFieldResultID,
        formFieldID,
        value: mediaAssetID,
        tableName,
        entityID,
      };
    }

    default:
      return {
        formFieldResultID,
        formFieldID,
        value: serializeValueByType(type, value),
        tableName,
        entityID,
      };
  }
}

export default function* () {
  yield all([
    yield takeLatest(formsRequest, formsSaga),
    yield takeLatest(formInfoRequest, formInfoSaga),
    yield takeLatest(createFormRequest, createFormSaga),
    yield takeLatest(updateFormRequest, updateFormSaga),
    yield takeLatest(updateFormStatusRequest, updateFormStatusSaga),
    yield takeLatest(deleteFormRequest, deleteFormSaga),
    yield takeLatest(deleteFormFieldRequest, deleteFormFieldSaga),
    yield takeLatest(fillFormRequest, fillFormSaga),
    yield takeLatest(updateFormSettingsRequest, updateFormSettingsSaga),
    yield takeLatest(formFieldsRequest, formFieldsSaga),
    yield takeLatest(formEmailSettingsRequest, formEmailSettingsSaga),
    yield takeLatest(updateFormEmailSettingsRequest, updateFormEmailSettingsSaga),
  ]);
}
